<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import Monitor from "@/services/Monitor";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import _ from 'underscore';

export default {
  components: { Layout, PageHeader, flatPickr},
  page: {
    title: "API Errors",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "CRON Log",
      items: [
        {
          text: "Logs",
        },
        {
          text: "CRON Log",
          active: true,
        },
      ],
      selectedTenant: '',
      tenantList: [],
      isBusy: false,
      showLoader:false,
      error: '',
      tableData: [],
      tenant: [],
      from_date: '',
      to_date: '',
      datetimeconfig: {
        enableTime: true,
        time_24hr: true,
        dateFormat: "d-m-Y H:i:s",
        minuteIncrement: 1,
        altInput: true,
        altFormat: "d-m-Y H:i",
      },
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      selectedStatus: '',
      status: [

          { value:'success', text: 'Success' },
          { value: 'ongoing', text: 'Ongoing' },
          { value: 'failed', text: 'Failed' },


      ],
      sortBy: "timestamp",
      sortDesc: true,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true
        },
        {
          key: "cron",
          label: "Cron",
          sortable: false
        },
        {
          key: "tenant",
          label: "Tenant",
          sortable: true
        },
        {
          key: "status",
          label: "Status",
          sortable: true
        },
        {
          key: "arguments",
          label: "Arguments",
          sortable: false
        },
        {
          key: "response",
          label: "Response",
          sortable: false
        },
        {
          key: "start_at",
          label: "Start At",
          sortable: true
        },
        {
          key: "end_at",
          label: "End At",
          sortable: true
        },
        {
          key: "time_difference",
          label: "Time",
          sortable: false
        },
        "action",
      ]
    };
  },
  destroyed() {
    clearInterval(this.timer);
  },
  middleware: "authentication",
  async mounted() {
    try {
      await this.getTenants();
    } catch (error) {
      console.error('Error during component initialization:', error);
    }
  },
  methods: {
    getTimeDifference(startAt, endAt) {
      if (!startAt || !endAt) return '';

      const startTime = new Date(startAt);
      const endTime = new Date(endAt);
      const timeDiff = (endTime - startTime) / 1000;

      const hours = Math.floor(timeDiff / 3600);
      const minutes = Math.floor((timeDiff % 3600) / 60);
      const seconds = Math.floor(timeDiff % 60);

      return `${hours}h ${minutes}m ${seconds}s`;
    },
    getFilters(){
      this.totalRows = 0 ;
      let filter_string = 'limit=1000';
      if (this.selectedStatus) {
        filter_string += `&status=${this.selectedStatus}`;
      }
      console.log(this.selectedTenant)
      if (this.selectedTenant) {
        filter_string += `&tenant=${this.selectedTenant}`;
      }
      if (this.from_date) {
        filter_string += `&from_date=${this.from_date}`;
      }
      if (this.from_date) {
        filter_string += `&to_date=${this.to_date}`;
      }
      return filter_string;
    } ,

    async getCronLogs() {
      this.toggleBusy();
      try {
        var filters = this.getFilters();
        const response = await Monitor.getCronLogs(filters);
        this.tableData = response.data.data.map(item => ({
          id: item.id,
          tenant: item.tenant,
          cron: item.cron,
          arguments: item.arguments,
          response: item.response,
          start_at: item.start_at,
          end_at: item.end_at,
          status: item.status
        }));

        this.totalRows = this.tableData.length;

      } catch (error) {
        this.error = error.response?.data?.error || "Error fetching data";
        this.tableData = [];
        this.totalRows = 0;
      } finally {
        this.toggleBusy();
      }
    },
    async getTenants() {
      try {
        const response = await Monitor.getTenants();
        this.tenantList = response.data.tenants.map(tenant => ({value: tenant, text: tenant }));
      } catch (error) {
        console.error("Error fetching tenants:", error);
      }

    },
    resetFilters() {
     this.selectedTenant  = ''
      this.selectedStatus = ''
      this.from_date = ''
      this.to_date = ''
    },
  }


};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="col-md-12">
          <div class="custom-accordion">
            <a
                class="text-body fw-semibold pb-2 d-block"
                data-toggle="collapse"
                href="javascript: void(0);"
                role="button"
                aria-expanded="false"
                v-b-toggle.categories-collapse
            >
              <i class="mdi mdi-chevron-up accor-down-icon text-primary me-1"></i>Filters
            </a>
            <b-collapse visible id="categories-collapse">
              <div class="card p-2 border shadow-none">
                <div class="row">
                  <div class="col-sm-12 col-md-3">
                    <label class="control-label form-label">Select Tenant</label>
                    <b-form-select
                        v-model="selectedTenant"
                        :options="tenantList"
                        aria-describedby="status-feedback"
                    ></b-form-select>
                  </div>
                  <div class="col-sm-12 col-md-3">
                    <label class="control-label form-label">Status</label>
                    <b-form-select
                        v-model="selectedStatus"
                        :options="status"
                        aria-describedby="status-feedback"
                    ></b-form-select>
                  </div>
                  <div class="col-sm-12 col-md-3">
                    <b-form-group label="Date (from)" label-for="formrow-date-input" class="mb-3">
                      <flat-pickr
                          v-model="from_date"
                          :config="datetimeconfig"
                          class="form-control"
                          placeholder="Select date"
                      />
                    </b-form-group>
                  </div>
                  <div class="col-sm-12 col-md-3">
                    <b-form-group label="Date (to)" label-for="formrow-date-input" class="mb-3">
                      <flat-pickr
                          v-model="to_date"
                          :config="datetimeconfig"
                          class="form-control"
                          placeholder="Select date"
                      />
                    </b-form-group>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col d-inline-flex">
                    <button type="button" class="btn btn-success me-2 w-lg" @click="getCronLogs">Filter</button>
                    <button type="button" class="btn btn-primary w-lg" @click="resetFilters">Reset</button>
                  </div>
                </div>

              </div>
            </b-collapse>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="custom-accordion">
              <a class="text-body fw-semibold pb-2 d-block" data-toggle="collapse"
                 href="javascript: void(0);" role="button" aria-expanded="false"
                 v-b-toggle.filters-collapse>
              </a>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm"
                                   :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>

              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                                  class="form-control form-control-sm ms-2"></b-form-input>
                  </label>
                </div>
              </div>

            </div>

            <div class="table-responsive mb-0">
              <b-table
                  :busy="isBusy"
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  show-empty
                  empty-text="No Data Found">

                <template #table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle text-primary"></b-spinner>
                    <strong class="text-primary">Loading...</strong>
                  </div>
                </template>

                <template v-slot:cell(arguments)="tableData">
                  <div class="d-flex align-items-center">
                                        <span v-if="tableData.item.arguments && tableData.item.arguments.length > 30" class="text-nowrap">
                                            {{ tableData.item.arguments.slice(0, 30) + '...' }}
                                        </span>
                    <a
                        v-if="tableData.item.arguments && tableData.item.arguments.length > 30"
                        v-b-popover.hover.html.bottom="tableData.item.arguments"
                        class="elementPointed ms-2"
                        title="Arguments"
                        size="sm"
                    >
                      <i class="uil uil-eye font-size-18"></i>
                    </a>
                    <span v-else>{{ tableData.item.arguments }}</span>
                  </div>
                </template>

                <template v-slot:cell(response)="tableData">
                  <div class="d-flex align-items-center">
                                        <span v-if="tableData.item.response && tableData.item.response.length > 30" class="text-nowrap">
                                            {{ tableData.item.response.slice(0, 30) + '...' }}
                                        </span>
                    <a
                        v-if="tableData.item.response && tableData.item.response.length > 30"
                        v-b-popover.hover.html.bottom="tableData.item.response"
                        class="elementPointed ms-2"
                        title="Response"
                        size="sm"
                    >
                      <i class="uil uil-eye font-size-18"></i>
                    </a>
                    <span v-else>{{ tableData.item.response }}</span>
                  </div>
                </template>
                <template v-slot:cell(time_difference)="data">
                  <span>{{ getTimeDifference(data.item.start_at, data.item.end_at) }}</span>
                </template>

              </b-table>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 col-md-5">
                <div class="dataTables_info">
                  Showing {{ (currentPage - 1) * perPage + 1 }} to
                  {{ (currentPage - 1) * perPage + totalRows  }} of {{ totalRows }} entries
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-end">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div  class="text-center mt-4 p-4">
            <strong>Use the filter to display the data</strong>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>
/* Ensure the placeholder is visible */
.flatpickr-input::placeholder {
  color: #6c757d; /* Bootstrap's default placeholder color */
  opacity: 1; /* Ensure it's not transparent */
}
</style>